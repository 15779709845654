// Setting default Url and headers with Loggedin user Token
import { config } from '../api/api';
import setupAxiosInterceptor from '../utils/axios-interceptor/axios-interceptor';
import { io } from 'socket.io-client';

// const socket = io(config.backendUrl, {
//   path: '/trip/generate', // Path should match the server's configuration
// });

let axios;
axios = setupAxiosInterceptor();

// subscribe to newslatter
const userSubscribe = async (email) => {
  let URL = 'users/subscribe/' + email;
  return axios.post(URL);
};
// user login
const userLogin = async (json) => {
  let URL = 'users/login/';
  return await axios.post(URL, json);
};
// verify login otp
const userVerifyOTP = async (json) => {
  let URL = 'users/verifyOTP';
  return await axios.post(URL, json);
};
// resend verify otp
const userResendVerifyOTP = async (json) => {
  let URL = 'users/resendOTPVerificationCode';
  return await axios.post(URL, json);
};
// sign-up as new user
const userSignUp = async (json) => {
  let URL = 'users/sign-up';
  return await axios.post(URL, json);
};
// send password reset mail to user
const userPasswordReset = async (json) => {
  let URL = 'users/requestPasswordReset';
  return await axios.post(URL, json);
};
// user password update
const userPasswordUpdate = async (json) => {
  let URL = 'users/resetPassword';
  return await axios.post(URL, json);
};

// user password update only for loggedIn user
const userAfterLoginResetPasswordUpdate = async (json) => {
  let URL = 'users/changePassword';
  return await axios.post(URL, json);
};

// request reset password OTP(only for login screen)
const userResetPassWordOTP = async (json) => {
  let URL = 'users/requestOTP';
  return await axios.post(URL, json);
};

// reset password(only for login screen)
const userLoginResetPassWord = async (json) => {
  let URL = 'users/createPassword';
  return await axios.post(URL, json);
};

const userEmailUpdateOTP = async (json) => {
  let URL = 'users/updateEmailOTP';
  return await axios.post(URL, json);
};

const userEmailUpdate = async (json) => {
  let URL = 'users/updateEmailAddress';
  return await axios.post(URL, json);
};

// get top destinantion
const getTopDestnationList = async () => {
  let URL = 'content/getList/topDestinations';
  return await axios.get(URL);
};
// get Upcoming Flight
const getUpcomingFlight = async () => {
  let URL = '/book/getUpcomingFlight';
  return await axios.get(URL);
};

// get upcoming hotel reservation
const getUpcomingHotelReservation = async (queryParams) => {
  let URL = `/hotel-reservation/upcoming${queryParams ? queryParams : ''}`;
  return await axios.get(URL);
};

// get user's Booking
const getUserBookings = async (queryParams) => {
  let URL = `/user/profile/getUserBookings${queryParams ? queryParams : ''}`;
  return await axios.get(URL);
};
// get popular attractions countries list
const getPopularAttractionCountriesList = async () => {
  let URL = 'content/getList/popularAttractions/countries';
  return await axios.get(URL);
};
// get popular attractions list
const getPopularAttractionList = async (json) => {
  let URL = `content/getList/popularAttractions?cityName=${json.cityName}`;
  return await axios.get(URL);
};
// get background images
const getBackgroundImages = async (json) => {
  let URL = `content/getList/images?keyword=${json}`;
  return await axios.get(URL);
};
// get cities list
const getCitiesList = async (json) => {
  let URL = `content/getList/cities?search=${json.search}&page=${json.page}&sizePerPage=${json.sizePerPage}`;
  return await axios.get(URL);
};
// get a user profile
const getAUserProfile = async () => {
  let URL = `user/profile/getProfile`;
  return await axios.get(URL);
};

const updateUserProfile = async (json) => {
  const data = {
    first_name: json.first_name ? json.first_name : null,
    last_name: json.last_name ? json.last_name : null,
    email: json.email,
    phone: json.phone ? json.phone : null,
    dob: json.dob ? json.dob : null,
  };
  let URL = `user/profile/`;
  return await axios.put(URL, data);
};
// get generated trip
// const getGeneratedTrip = async (json) => {
//   console.log("-- json ---",json);
//   let URL = 'trip/generate';
//   delete json.userId;
//   return await axios.post(URL, json);
// };

const getGeneratedTrip = (json) => {
  const socket = io(config.backendUrl, {
    path: '/trip/generate',
  });
  return new Promise((resolve, reject) => {
    // console.log('-- json ---', json);

    // delete json.userId;

    socket.emit('generate-trip', json);

    socket.on('all-trip-generated', (response) => {
      // console.log('Trip generated: ', response);

      // Check if the response is valid
      if (response && response.status === 'success') {
        // Resolve the promise with the response data
        resolve(response);
      } else {
        // Reject the promise with an error if something goes wrong
        reject(new Error('Error generating trip'));
      }
    });
  });
};

const deleteTripById = async (json) => {
  let URL = `trip/deleteTrip?tripId=${json.tripId}`;
  return await axios.get(URL);
};
// get trip by user id
const getTripByUserId = async (limit, page) => {
  if (limit && page) {
    const URL = `trip/getTripPlans?limit=${limit}&page=${page}`;
    return await axios.get(URL);
  }
  const URL = `trip/getTripPlans`;
  return await axios.get(URL);
};
const getTripById = async (json) => {
  const URl = `trip/getTripPlanById/${json.tripId}`;
  return await axios.get(URl);
};
const getUpcomingTripData = async (json) => {
  const URl = `content/getUpcomingTrip/`;
  return await axios.get(URl);
};
const getTripPlanByTripId = async (json) => {
  const URL = `trip/getTripPlanByTripId/${json.tripId}`;
  return await axios.get(URL);
};
const updateTripHotel = async (json) => {
  const URL = `trip/updateTripHotel`;
  return await axios.post(URL, json);
};
const updateTripFlight = async (json) => {
  const URL = `trip/updateTripFlight`;
  return await axios.post(URL, json);
};
const getActivities = async (json) => {
  let URL = `content/activities?activity=${json.category}&place=${json.place}`;

  if (json.pageToken) {
    URL = `&pageToken=${json.pageToken}`;
  }
  return await axios.get(URL);
};
const getPlaceDetails = async (json) => {
  let URL = `content/place/${json.place_id}`;
  return await axios.get(URL);
};
const addActivity = async (json) => {
  const URL = `trip/addActivity`;
  return await axios.post(URL, json);
};
const removeActivity = async (json) => {
  const URL = `trip/removeActivity`;
  return await axios.post(URL, json);
};
const replaceActivity = async (json) => {
  const URL = `trip/replaceActivity`;
  return await axios.post(URL, json);
};
// get airport list
const getAirportsList = async (json) => {
  let URL = `content/getList/airports?search=${json.search}&page=${json.page}&sizePerPage=${json.sizePerPage}`;
  return await axios.get(URL);
};
// get popular routes
const getPopularRoutes = async (json) => {
  let URL = `content/getList/popularRoutes?city=${json.city}&country=${json.country}&limit=${json.limit}`;
  return await axios.get(URL);
};
// get flight search results
const getFlightSearchResults = async (json) => {
  // json.payload.adults = 1;
  const baseUrl = `flight/search?sessionId=${json.filters.sessionId}`;
  let URL = `${baseUrl}&stops=${
    json.filters.stops ? json.filters.stops : '0,1,2'
  }`;
  if (json.filters?.departureTime?.length > 0) {
    URL = `${baseUrl}&departureTime=${json.filters.departureTime}`;
  }
  if (json.filters?.arrivalTime?.length > 0) {
    URL = `${baseUrl}&arrivalTime=${json.filters.arrivalTime}`;
  }
  if (json.filters?.airlineInclude?.length > 0) {
    URL = `${baseUrl}&airlineInclude=${json.filters.airlineInclude}`;
  }
  if (json.filters?.duration > 0) {
    URL = `${baseUrl}&duration=${json.filters.duration}`;
  }
  if (json.filters?.sortBy?.length > 0) {
    URL = `${baseUrl}&sortBy=${json.filters.sortBy}`;
  }
  return await axios.post(URL, json.payload, { cancelToken: json.cancelToken });
};

const getChangeFlightSearchResults = async (json) => {
  let URL = `flight/change-flight`;
  if (json) {
    URL = `&newFlightDate=${json.newFlightDate}`;
  }
  return await axios.get(URL, json.payload, { cancelToken: json.cancelToken });
};

const getchangeFlightProcessResult = async (json) => {
  let URL = `/change-flight/process`;

  return await axios.post(URL, json);
};

const cancelFlightResults = async (json) => {
  let URL = `flight/booking/cancel`;
  return await axios.post(URL, json);
};

const getFlightDetails = async (json) => {
  let URL = `/change-flight/${json.bookingId}`;

  return await axios.get(URL);
};

// hotel search page

// get hotel and cities
const getHotelAndCities = async (json) => {
  let URL = `content/getList/hotelAndCity?name=${json.search}`;
  return await axios.get(URL);
};
//get Locations for car rental
const getLocations = async (json) => {
  let URL = `car/search-car-location?name=${json?.name}&locale=${json?.locale}`;
  return await axios.get(URL);
};
// get top cities
const getPopularCities = async (json) => {
  let URL = `content/getList/popularCities`;
  return await axios.get(URL);
};

// get hotel list
const getHotelList = async (json) => {
  let URL = `hotel/search?destType=${json.destType}&destId=${json.destId}&checkinDate=${json.checkinDate}&checkoutDate=${json.checkoutDate}&adultsNumber=${json.adultsNumber}&orderBy=${json.orderBy}&filterByCurrency=${json.filterByCurrency}&locale=${json.locale}&roomNumber=${json.roomNumber}&page_number=${json.page_number}`;
  if (json.childrenNumber > 0) {
    URL += `&childrenNumber=${json.childrenNumber}`;
  }
  if (json.categoriesFilterIds?.length > 0) {
    URL += `&categoriesFilterIds=${json.categoriesFilterIds}`;
  }
  return await axios.get(URL);
};
// get hotel by city name
const getHotelByCityName = async (json) => {
  let URL = `content/getList/popularHotels?cityName=${json.cityName}`;
  return await axios.get(URL);
};
const getHotelByCity = async (json) => {
  let URL = `hotel/getHotelByCityName?city=${json.city}`;
  return await axios.get(URL, json);
};
// get hotel by id
const getHotelById = async (json) => {
  let URL = `hotel/get?hotelId=${json.hotelId}&childer_number=${json.childer_number}&checkIn=${json.checkIn}&checkOut=${json.checkOut}&adults_number=${json.adults_number}&currencyCode=${json.currencyCode}`;
  return await axios.get(URL);
};
// get hotel metadata by id
const getHotelMetadataById = async (json) => {
  let URL = `hotel/get/metadata?hotelId=${json}`;
  return await axios.get(URL);
};
// get hotel review by id
const getHotelReviewById = async (json) => {
  let URL = `hotel/get/reviews?hotelId=${json.hotelId}&sortType=${json.sortType}`;
  if (json.languageFilter.length > 0) {
    URL += `&languageFilter=${json.languageFilter}`;
  }
  if (json.customerType.length > 0) {
    URL += `&customerType=${json.customerType}`;
  }
  if (json.timeOfYear.length > 0) {
    URL += `&timeOfYear=${json.timeOfYear}`;
  }
  if (json.topic.length > 0) {
    URL += `&topic=${json.topic}`;
  }
  return await axios.get(URL);
};
// get hotel rooms by hotel id
const getHotelRoomsById = async (json) => {
  let URL = `hotel/get/rooms?currencyCode=${json.currencyCode}&hotelId=${json.hotelId}&childer_number=${json.childer_number}&checkIn=${json.checkIn}&checkOut=${json.checkOut}&adults_number=${json.adults_number}`;
  return await axios.get(URL);
};
// get hotels cordinate by city
const getHotelsCordinateByCity = async (json) => {
  let URL = `hotel/cordinates?city=${json}`;
  return await axios.get(URL);
};
// get flight revalid
const getFlightRevalidate = async (json) => {
  let URL = `flight/validate`;
  return await axios.post(URL, json);
};

// add Frequent Traveler
const addFrequentTraveler = async (json) => {
  let URL = `user/profile/frequentTravelerInfo`;
  return await axios.post(URL, json);
};
// update Frequent Traveler
const updateFrequentTraveler = async (json) => {
  let URL = `user/profile/frequentTravelerInfo/${json.travelerId}`;
  return await axios.put(URL, json);
};
// delete Frequent Traveler
const deleteFrequentTraveler = async (json) => {
  let URL;
  if (json.id) {
    URL = `user/profile/frequentTravelerInfo/${json.id}`;
    return await axios.delete(URL);
  } else if (json.ids) {
    const query = new URLSearchParams();
    query.append('ids', json.ids.join(','));
    URL = `user/profile/frequentTravelerInfo?${query.toString()}`;
    return await axios.delete(URL);
  }
};

// adding new booking bucks entry
const addExplrarBucks = async (json) => {
  let URL = '/bucks/addexplrarbucks';
  return await axios.post(URL, json);
};
// update exisitng users bucks entry
const updateExplrarBucks = async (json) => {
  let URL = '/bucks/updateexplrarbucks';

  return await axios.patch(URL, json);
};

// delete outdated bucks entry
const validateBucks = async (json) => {
  let URL = 'bucks/validateBucks';

  return await axios.get(URL);
};
// delete outdated bucks entry
const getBuckDetails = async (bookingId) => {
  let URL = `bucks/BuckDetails?bookingId=${bookingId}`;

  return await axios.get(URL);
};

// currency change
const currencyChange = async (json) => {
  let url = `users/currency/convert?from=${json.fromCur}&to=${json.toCur}&amount=${Number(json.amount)}`;
  const response = await axios.get(url);

  return response.data?.data?.conversion_result;
};

//get the supported currencies for the third party api used.
const currencyCodes = async (json) => {
  let url = `users/currency/codes`;
  const response = await axios.get(url);

  return response.data.data;
};

// get exchange rante for a currency
const getExchangeRate = async (json) => {
  let url = `users/currency/exchangeRate?preferedCurrency=${json.preferedCurrency}`;
  const response = await axios.get(url);

  return response.data.data;
};

// get flight lounge
const getAirportLounge = async (json) => {
  let URL = `flight/getLounge`;
  return await axios.post(URL, json);
};
// get events
const getEventsList = async (json) => {
  let URL = `events/get?start=0&rows=20&city_ids=${json?.city_ids ? json?.city_ids : ''}&country_ids=${
    json?.country_ids ? json?.country_ids : ''
  }&category_ids=${json?.category_ids ? json?.category_ids : ''}&min_price=${
    json?.min_price ? json?.min_price : 0
  }&max_price=${json?.max_price ? json?.max_price : 1000}&eventdate_to=${
    json?.eventdate_to ? json?.eventdate_to : ''
  }&eventdate_from=${json?.eventDate_from ? json?.eventDate_from : ''}`;

  if (json?.searchQuery) {
    URL = `&keyword=${json?.searchQuery}`;
  }
  return await axios.get(URL);
};

const getEventById = async (json) => {
  let URL = `events/get/event?id=${json.eventId}&domain=${json.domain}`;
  return await axios.get(URL);
};

const getEventsCategories = async (json) => {
  let URL = `events/getCategory`;
  return await axios.get(URL);
};
const getEventsCountries = async (json) => {
  let URL = `events/get/Country`;
  return await axios.get(URL);
};
const getEventCities = async (country_id) => {
  let URL = `/events/get/cities?country_id=${country_id}`;
  return await axios.get(URL);
};

/*
  1. create the api service into the service file
*/
// stripe

// get client secret
const createPaymentIntent = async (json) => {
  try {
    let URL = `/stripe/create-payment-intent`;
    return await axios.post(URL, json);
  } catch (err) {
    console.log(err);
  }
};
// get payment reciept
const getPaymentReciept = async (json) => {
  let URL;
  if (json.type === 'flight') {
    URL = `stripe/retrieve-payment-intent?paymentIntent=${json.paymentIntent}&type=${json.type}&flightSessionId=${json.flightSessionId}&processType=${json?.processType}`;
    return await axios.post(URL, json);
  } else {
    URL = `stripe/retrieve-payment-intent?paymentIntent=${json.paymentIntent}&type=${json.type}`;
  }
  return await axios.post(URL, json);
};

//initiate stripe refund
const initiateStripeRefund = async (json) => {
  let URL = `stripe/refundprocess`;
  const data = await axios.post(URL, json);
  return data;
};
//initiate refund
const initiatePaypalRefund = async (json) => {
  let URL = `paypal/refundprocess`;
  const data = await axios.post(URL, json);
  return data;
};

// Booking
// get hotel book
const getHotelBook = async (json) => {
  let URL = `/book/hotels/${json.hotelId}`;
  return await axios.get(URL);
};

const cancelHotelResults = async (json) => {
  let URL = `hotel/booking/cancel`;
  return await axios.post(URL, json);
};

// get flight booking confirmation
const getFlightBook = async (json) => {
  if (json.flightId === null) return;
  let URL = `/book/flights/${json.flightId}`;
  return await axios.get(URL, json);
};

const getSearchCarList = async (json) => {
  const carUrl = `https://api.explrar.com/v1/car/search-rental-car?currency=${json?.currency}&drop_off_longitude=${json?.drop_off_longitude}&pick_up_latitude=${json?.pick_up_latitude}&pick_up_datetime=${json?.pick_up_datetime}&pick_up_longitude=${json?.pick_up_longitude}&drop_off_datetime=${json?.drop_off_datetime}&drop_off_latitude=${json?.drop_off_latitude}&sort_by=price_low_to_high&locale=en-gb&from_country=it`;
  return await axios.get(carUrl);
};

const getIpData = async (json) => {
  const ipUrl = `https://ipgeolocation.abstractapi.com/v1/?api_key=${config.ipgeo}`;
  return await axios.get(ipUrl);
};

const getLoggedInUserToken = () => {
  return localStorage.getItem('access_token');
};

// update profile image
const uploadProfilePic = async (file, userId) => {
  if (!userId) throw new Error('User ID is required');

  const formData = new FormData();
  formData.append('file', file);

  const url = `/user/profile/saveProfilePic?userId=${userId}`;

  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data.data;
};

const saveReview = async (json) => {
  let URL = '/users/feedback';

  return await axios.post(URL, json);
};

const getPackings = async () => {
  return await axios.get('/packing');
};

const getPackingDetails = async (id) => {
  return await axios.get(`/packing/${id}`);
};

const generatePackingList = async (json) => {
  return await axios.post('/packing/generate', json);
};

const deletePacking = async (id) => {
  return await axios.delete(`/packing/${id}`);
};

const sendEmailWithFile = async (json) => {
  return await axios.post('/users/itineraryPdfActions', json);
};
const downloadPdfItinerary = async (json) => {
  return await axios.post('/users/itineraryPdfActions', json, {
    responseType: 'json',
  });
};
const sendEmailWithReceipt = async (json) => {
  return await axios.post('/users/getFlightReceipt', json);
};
const downloadReceipt = async (json) => {
  return await axios.post('/users/getFlightReceipt', json, {
    responseType: 'json',
  });
};

const addTrafficSource = async (json) => {
  return await axios.post('/users/traffic', json);
};

const logVisit = async () => {
  try {
    console.log('Here is the log visit, Attempting to login');
    let URL = '/users/log-visit';
    console.log('URL:', URL);
    const response = await axios.post(URL);
    console.log('Visit logged successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Failed to log visit:', error);
  }
};

// send hotel booking success email
const sendHotelBookingSuccessEmail = async (json) => {
  let URL = '/hotel-reservation/sendHotelBookingSuccessEmail';
  return await axios.post(URL, json);
};

export {
  saveReview,
  userSubscribe,
  userLogin,
  userVerifyOTP,
  userResendVerifyOTP,
  userSignUp,
  userPasswordReset,
  userPasswordUpdate,
  userAfterLoginResetPasswordUpdate,
  userResetPassWordOTP,
  userLoginResetPassWord,
  userEmailUpdateOTP,
  userEmailUpdate,
  getTopDestnationList,
  getPopularAttractionCountriesList,
  getPopularAttractionList,
  getBackgroundImages,
  getAUserProfile,
  updateUserProfile,
  getCitiesList,
  getGeneratedTrip,
  getTripByUserId,
  getTripById,
  getTripPlanByTripId,
  deleteTripById,
  updateTripHotel,
  updateTripFlight,
  getActivities,
  getPlaceDetails,
  addActivity,
  removeActivity,
  replaceActivity,
  getAirportsList,
  getPopularRoutes,
  getFlightSearchResults,
  getChangeFlightSearchResults,
  getchangeFlightProcessResult,
  cancelFlightResults,
  getFlightDetails,
  getLoggedInUserToken,
  cancelHotelResults,
  getPopularCities,
  getHotelAndCities,
  getHotelList,
  getHotelByCityName,
  getHotelByCity,
  getHotelById,
  getUpcomingTripData,
  getHotelMetadataById,
  getUpcomingFlight,
  getHotelReviewById,
  getFlightRevalidate,
  addFrequentTraveler,
  updateFrequentTraveler,
  deleteFrequentTraveler,
  addExplrarBucks,
  updateExplrarBucks,
  validateBucks,
  getBuckDetails,
  currencyChange,
  getAirportLounge,
  getHotelRoomsById,
  getHotelsCordinateByCity,
  getEventsList,
  getEventById,
  getEventsCategories,
  getLocations,
  getEventsCountries,
  getEventCities,
  // Stripe
  createPaymentIntent,
  getPaymentReciept,
  initiateStripeRefund,
  initiatePaypalRefund,
  // Booking
  getHotelBook,
  getFlightBook,
  // car rental
  getSearchCarList,
  getIpData,
  uploadProfilePic,
  // Packing List
  getPackings,
  getPackingDetails,
  generatePackingList,
  deletePacking,
  currencyCodes,
  getExchangeRate,
  sendEmailWithFile,
  getUserBookings,
  // add traffic source
  addTrafficSource,
  downloadPdfItinerary,
  // hotel reservation
  getUpcomingHotelReservation,
  sendHotelBookingSuccessEmail,
  // flight receipt
  sendEmailWithReceipt,
  downloadReceipt,
  logVisit,
};
